import {
  isEmpty,
  some,
  find,
  every,
  intersection,
  difference,
  groupBy,
  keyBy,
  uniqBy,
  uniq,
  differenceBy,
  sortBy,
  first,
} from 'lodash';
import Product from '.';
import {
  ITSAttribute,
  ITSAttributeOption,
  ITSDefaultAttributes,
  ITSProduct,
  Variation,
} from '../../utils/typesense/types/product';
import { KeyAndValue } from '../../utils/typesense/types/global';
import VariationProduct from './VariationProduct';

export default class VariableProduct extends Product {
  private _defaultAttributes: ITSDefaultAttributes;
  private _variations: Variation[];

  constructor(props: ITSProduct) {
    super(props);
    this._defaultAttributes = props.defaultAttributes;
    this._variations = props.variations;
  }

  override getDefaultAttributes() {
    return this._defaultAttributes;
  }

  override getVariations() {
    return this._variations;
  }

  override findMatchingVariations(selectedAttributes: ITSDefaultAttributes) {
    // if there are no selected attributes then return all variations
    if (isEmpty(selectedAttributes)) {
      return this.getVariations();
    }

    const foundVariations = this.getVariations().filter(
      (variation: Variation) => {
        return this.variationMatched(variation, selectedAttributes);
      },
    );

    return foundVariations;
  }

  override getVariationsAttributes(selectedAttributes: ITSDefaultAttributes) {
    return {};
  }

  override variationMatched(
    variation: Variation,
    selectedAttributes: ITSDefaultAttributes,
  ) {
    const selectedAttrValues = Object.values(selectedAttributes);
    const variationAttrValues = Object.values(variation.attributes);

    const attributeInterSection = intersection(
      variationAttrValues,
      selectedAttrValues,
    );

    if (!isEmpty(attributeInterSection)) {
      return true;
    }

    return false;
  }

  #getKeyedAttributes() {
    const attributes = this.getAttributes();

    /**
     * Modify attributes to be keyed by attribute slug and options keyed by slug too
     */
    let newAttrs = attributes?.map((attribute) => {
      let { options, ...modifiedAttr } = attribute;
      const groupedOptions = keyBy(options, 'slug');
      return { ...modifiedAttr, options: groupedOptions };
    });

    let modifiedAttrs = keyBy(newAttrs, 'slug');

    return modifiedAttrs;
  }

  #getAvailableAttributeFromVariations(variations: Variation[]) {
    let availableAttrs: KeyAndValue[] = [];

    variations.map((variation) => {
      for (const attrSlug in variation.attributes) {
        let modifiedAttrsKey = attrSlug.replace('attribute_', '');
        let optionSlug = variation.attributes[attrSlug];

        if (!availableAttrs.hasOwnProperty(modifiedAttrsKey)) {
          availableAttrs[modifiedAttrsKey] = [];
        }
        availableAttrs[modifiedAttrsKey].push(optionSlug);
      }
    });

    return availableAttrs;
  }

  /**
   * A method that gets all attributes base on the selected attribute and the available varations
   * @param selectedAttributes
   * @returns
   */
  override getAvailableAttributes(
    selectedAttributes?: ITSDefaultAttributes,
  ): ITSAttribute[] {
    let modifiedAttrs = this.#getKeyedAttributes();
    let variations = this.getVariations();

    if (!isEmpty(selectedAttributes)) {
      variations = this.getVariationsByAttributes(selectedAttributes);
    }

    console.log('selectedAttributes', selectedAttributes);
    console.log('variations', variations);

    let availableAttrs = this.#getAvailableAttributeFromVariations(variations);

    const finalAvailableAttributes: ITSAttribute[] = Object.keys(
      modifiedAttrs,
    ).map((attrKey) => {
      let { options, ...attr } = modifiedAttrs[attrKey];

      let attrOptions: ITSAttributeOption[];
      let attributeOptionSlugs = uniq(availableAttrs[attrKey]);
      const availableOptionsKeys = intersection(
        Object.keys(options),
        attributeOptionSlugs,
      );

      attrOptions = availableOptionsKeys.map((optionSlug) => {
        return modifiedAttrs[attrKey].options[`${optionSlug}`];
      });

      return { ...attr, options: attrOptions };
    });

    return finalAvailableAttributes;
  }

  override getVariationsByAttributes(
    selectedAttributes: ITSDefaultAttributes,
    exactMatch: boolean = false,
  ): Variation[] {
    const variations = this.getVariations();
    const attributes = this.getAttributes();
    if (isEmpty(selectedAttributes)) {
      return variations;
    }
    let searchAttr = {};

    for (const attrSlug in selectedAttributes) {
      let modifiedAttrsKey = 'attribute_' + attrSlug;
      let optionSlug = selectedAttributes[attrSlug];
      searchAttr[modifiedAttrsKey] = optionSlug;
    }

    const attributeCount = attributes?.length;

    let foundVariations: any = [];
    variations.map((variation) => {
      let score = 0;

      for (const attributeSlug in searchAttr) {
        // if the attribute option value then increase the score because it means at least one attribute value is found in this variation

        if (variation.attributes[attributeSlug] == searchAttr[attributeSlug]) {
          score += 1;
        }
      }

      let found = {
        ...variation,
        score,
      };

      // if score more than 1 then it means a variation having the given attribute was found
      if (score > 0) {
        foundVariations.push(found);
      }
    });

    return sortBy(foundVariations, 'score').reverse();
  }

  getVariationByAttributes(
    selectedAttributes: ITSDefaultAttributes,
  ): VariationProduct | undefined {
    const foundVariations = this.getVariationsByAttributes(
      selectedAttributes,
      true,
    );

    const variation = first(foundVariations);
    if (variation) {
      return new VariationProduct(variation);
    }

    return;
  }

  override getStockQuantity() {
    return this.getVariationsStock();
  }

  getVariationsStock() {
    const variations = this.getVariations();

    return variations.reduce((accumulator, variation) => {
      return variation.stockQuantity + accumulator;
    }, 0);
  }
}
