import { isEmpty, isNumber } from 'lodash';
import { useState, createContext, useRef, useEffect, useCallback } from 'react';
import uniqid from 'uniqid';
import { ADD_TO_CART, CUSTOM_ADD_TO_CART } from '@/utils/queries/product'
import { useMutation, useQuery } from '@apollo/client'
import { useSiteContext } from '../contexts/SiteContext'
import useIsBuildYourOwnHamer from '../utils/useIsBuildYourOwnHamper';
import useCallbackState from '../utils/hooks/useCallbackState';
import useIsVoucher from '../utils/useIsVoucher';
import { useQuantity } from '../utils/hooks/useQuantity';
import useAddToCartMutation from '../utils/hooks/useAddToCartMutation';
import ProductFactory from '../models/product/ProductFactory';
import { useGiftBox } from '../utils/hooks/useGiftBox';

export const ProductContext = createContext()

// TODO: We should deprecate and slow remove this and slowly put all codes inside ProductCtx.ts or codes be accessible to that file
export const ProductContextProvider = (props) => {

    const [stockStatus, setStockStatus] = useState(props.stockStatus ? props.stockStatus : 'OUT_OF_STOCK')
    const [stockQuantity, setStockQuantity] = useState(props.stockQuantity ? props.stockQuantity : null)

    const [addonDialogOpen, setAddonDialogOpen] = useState(false)
    const [addonDialogPanel, setAddonDialogPanel] = useState(0)
    const [addonFromProductPage, setAddonFromProductPage] = useState(false)
    const [selectedAddonGroup, setSelectedAddonGroup] = useState(-1);
    const [selectedAddons, setSelectedAddons] = useState([]);
    const [selectedAddonQty, setSelectedAddonQty] = useState(0)
    const [addonSummary, setAddonSummary] = useState({})
    const [addonQueryInputVars, setAddonQueryInputVars] = useState({})
    const [addonsTotal, setAddonsTotal] = useState(0)
    const [addonsTotalWeight, setAddonsTotalWeight] = useState(0)
    const [addons, setAddons] = useState([])
    const [addonDialogMsg, setAddonDialogMsg] = useState('')

    const [giftCardOpen, setGiftCardOpen] = useState(false)
    const [giftCardPanel, setGiftCardPanel] = useState('')
    const [selectedGiftCard, setSelectedGiftCard] = useState(null);
    const [giftCardSelectionError, setGiftCardSelectionError] = useState('');

    const [giftCards, setGiftCards] = useState([])
    const [occasionIndex, setOccasionIndex] = useState(null)
    const [selectedMessage, setSelectedMessage] = useState('')

    const [videoMessage, setVideoMessage] = useState([])
    const [selectedVideoMessage, setSelectedVideoMessage] = useState(null)

    const giftCardMessageEl = useRef(null)
    const [productPrice, setProductPrice] = useState(props.price)

    const isBuildYourOwnHamper = useIsBuildYourOwnHamer();
    const isVoucherProduct = useIsVoucher();

    const [hamperBox, setHamperBox] = useState({})
    const [choosenHamperBox, setChoosenHamperBox] = useState(null)

    const { fetchCart, miniCartState: [, setMiniCartOpen] } = useSiteContext()

    const [videoInstructionModal, setVideoInstructionModal] = useCallbackState(false);

    const [giftMsgOption, setGiftMsgOption] = useState(null)

    const { quantity: productQty, decrement, increment, onChange } = useQuantity({
        max: stockQuantity,
    });

    const parsedProduct = props.product;

    const giftBox = useGiftBox(props.addonsGroupy);

    useEffect(() => {

        if (true === videoInstructionModal) {
            const html = document.body.parentNode;
            html.style.overflow = 'hidden';
        }

    }, [videoInstructionModal])

    useEffect(() => {
        // Set context items
        setAddons(props.addonsGroupy)
        setGiftCards(props.addonsGiftStyle)

        const addonsLength = props.addonsGroupy.length;
        for (let i = 0; i < addonsLength; i++) {
            const addon = props.addonsGroupy[i];
            if (addon.group_name == 'Box') {
                setHamperBox(addon.addons[0])
                break;
            }
        }

        setVideoMessage(props.addonVideoMessage)

    }, [props.addonsGiftStyle, props.addonsGroupy, props.addonVideoMessage])

    const getCardOptions = useCallback(() => {
        return isEmpty(giftCards.options)
            ? []
            : Object.values(giftCards.options);
    }, [giftCards])

    const getVideoMsgOptions = useCallback(() => {
        return isEmpty(videoMessage.options)
            ? []
            : Object.values(videoMessage.options);
    }, [videoMessage])

    const getSelectedVideoMsgOption = useCallback(() => {
        const videoMsgOptions = getVideoMsgOptions();
        return videoMsgOptions[selectedVideoMessage]
    }, [selectedVideoMessage, getVideoMsgOptions])


    const resetProductContextState = () => {
        setSelectedGiftCard(null)
        setSelectedAddonQty(0)
        setAddonDialogOpen(false)
        setAddonDialogPanel(0)
        setAddonFromProductPage(false)
        setSelectedAddonGroup(-1)
        setSelectedAddons([])
        setSelectedAddonQty(0)
        setAddonSummary({})
        setAddonQueryInputVars({})
        setAddonsTotal(0)
        setAddonDialogMsg('')

        setGiftCardOpen(false)
        setGiftCardPanel('')
        setSelectedGiftCard(null)
        setGiftCardSelectionError('')
        setOccasionIndex(null)
        setSelectedMessage('')
        setGiftMsgOption(null)

        giftBox.setSelectedGiftBox(undefined)
    }

    const didSelectGiftCard = useCallback(() => {
        const giftCardItems = getCardOptions()
        if (
            isNumber(selectedGiftCard) &&
            isNumber(parseInt(props.id)) &&
            typeof giftCardItems[selectedGiftCard] !== 'undefined' &&
            giftMsgOption == 'GIFTCARD'
        ) {
            return true;
        }

        return false;
    }, [selectedGiftCard, getCardOptions, giftMsgOption, props.id])

    const didSelectVideoMsg = useCallback(() => {
        if (isNumber(selectedVideoMessage) && giftMsgOption == 'VIDEOMSG') {
            return true;
        }
        return false;
    }, [selectedVideoMessage, giftMsgOption]);


    const getHamper = useCallback((totalWeight) => {

        let choosenHamper = null, smallHamper = null, mediumHamper = null, largeHamper = null, extraLargehamper = null;

        if (hamperBox.options) {
            hamperBox.options.map((hamper, i) => {
                if (hamper.label_slug.includes('ingredients-small')) {
                    smallHamper = hamper;
                } else if (hamper.label_slug.includes('ingredients-medium')) {
                    mediumHamper = hamper;
                } else if (hamper.label_slug.includes('ingredients-large')) {
                    largeHamper = hamper;
                } else if (hamper.label_slug.includes('ingredients-extra-large')) {
                    extraLargehamper = hamper;
                }
            });

            if (totalWeight > 0 && totalWeight < 8) {
                // Small
                choosenHamper = smallHamper;
            } else if (totalWeight > 7 && totalWeight < 14) {
                // medium
                choosenHamper = mediumHamper;
            } else if (totalWeight > 13 && totalWeight < 31) {
                // large
                choosenHamper = largeHamper;
            } else if (totalWeight > 30) {
                //extra large
                choosenHamper = extraLargehamper;
            }
        }


        return choosenHamper;
    }, [hamperBox]);

    useEffect(() => {
        // When Dialog panel changes then we set the message to empty to remove it from other panel
        setAddonDialogMsg('')
        // setAddonDialogOpen((addonDialogPanel <= 0) ? false : true)
    }, [addonDialogPanel])

    useEffect(() => {
        if (isNumber(selectedGiftCard)) {
            setGiftCardSelectionError('')
            setGiftCardOpen(false)
        }
    }, [selectedGiftCard])


    useEffect(() => {
        let total = 0
        let totalWeight = 0
        // Empty addon query input vars before creating the input vars below
        const addonInputQuery = {}

        const addonSummaryKeys = Object.keys(addonSummary);
        addonSummaryKeys.map((addonGroupIndex) => {
            return Object.keys(addonSummary[addonGroupIndex]).map((skuIndex) => {
                const addonItem = addonSummary[addonGroupIndex][skuIndex]
                total += addonItem.total


                // Creating the add to cart addon query vars
                if (!addonInputQuery.hasOwnProperty(addonItem.field_name)) {
                    addonInputQuery[addonItem.field_name] = {};
                }

                if (!addonInputQuery[addonItem.field_name].hasOwnProperty(addonItem.label_slug)) {
                    addonInputQuery[addonItem.field_name][addonItem.label_slug] = {};
                }

                addonInputQuery[addonItem.field_name][addonItem.label_slug] = addonItem.qty;

                const weight = isNumber(parseFloat(addonItem.weight)) ? addonItem.weight : 0
                totalWeight += (parseFloat(weight) * addonItem.qty);

                return null
            })
        })
        setAddonQueryInputVars(addonInputQuery)

        const didSelect = didSelectGiftCard()
        if (didSelect) {
            const giftCardItems = getCardOptions()
            const giftCardPrice = giftCardItems[selectedGiftCard].price
            total += parseFloat(isEmpty(giftCardPrice) ? 0 : giftCardPrice);
        }

        const didSelectVMsg = didSelectVideoMsg();
        if (didSelectVMsg) {
            const selectedVideoMsgOption = getSelectedVideoMsgOption();
            const videoMsgPrice = selectedVideoMsgOption.price
            total += parseFloat(isEmpty(videoMsgPrice) ? 0 : videoMsgPrice);
        }

        if (isBuildYourOwnHamper && !isEmpty(giftBox.selectedGiftBox)) {
            if (giftBox.isBlackBox()) {
                const choosenHamper = getHamper(totalWeight);
                if (choosenHamper) {
                    setChoosenHamperBox(choosenHamper)
                    const hamperPrice = isEmpty(choosenHamper.price) ? 0 : choosenHamper.price;
                    total += parseFloat(hamperPrice);
                }
            } else {
                total += parseFloat(giftBox.selectedGiftBox.price)
                setChoosenHamperBox(null)
            }
        }

        setAddonsTotalWeight(totalWeight)
        setAddonsTotal(total)

    }, [giftBox.selectedGiftBox, isBuildYourOwnHamper, selectedAddonQty, getCardOptions, selectedGiftCard, addonSummary, didSelectGiftCard, getHamper, didSelectVideoMsg, getSelectedVideoMsgOption])

    const deductAddonQty = (qty) => {
        setSelectedAddonQty(selectedAddonQty - qty)
    }

    const increaseAddonQty = (qty) => {
        setSelectedAddonQty(selectedAddonQty + qty)
    }

    const updateAddonSummary = (addonItem) => {

        if (!addonSummary.hasOwnProperty(addonItem.groupIndex)) {
            addonSummary[addonItem.groupIndex] = {};
        }
        if (!addonSummary[addonItem.groupIndex].hasOwnProperty(addonItem.sku)) {
            addonSummary[addonItem.groupIndex][addonItem.sku] = {};
        }
        addonSummary[addonItem.groupIndex][addonItem.sku] = addonItem;

    }

    const removeAddonItemSummary = (sku, addonGroupIndex) => {
        let groupIndex = selectedAddonGroup
        if (typeof addonGroupIndex !== "undefined") {
            groupIndex = addonGroupIndex
        }

        if (
            addonSummary.hasOwnProperty(groupIndex) &&
            addonSummary[groupIndex].hasOwnProperty(sku)
        ) {
            delete addonSummary[groupIndex][sku]
            if (isEmpty(addonSummary[groupIndex])) {
                // If there is nothing in the groupIndex then we also delete it. 
                delete addonSummary[groupIndex]
            }
        }
    }

    /**
     *  Get the selected addon state for a product by sku and also the selected AddonGroup
     * 
     * @param {String} sku 
     * @param {Int} addonGroupIndex 
     * @returns 
     */
    const getSelectedAddonBysku = (sku, addonGroupIndex) => {
        let groupIndex = selectedAddonGroup
        if (typeof addonGroupIndex !== "undefined") {
            groupIndex = addonGroupIndex
        }

        if (
            addonSummary.hasOwnProperty(groupIndex) &&
            addonSummary[groupIndex].hasOwnProperty(sku)
        ) {
            return addonSummary[groupIndex][sku]
        }
        return null
    }

    const nextAddonPanel = () => {
        const newPanel = parseInt(addonDialogPanel) + 1
        setAddonDialogPanel(newPanel)
    }

    const previousAddonPanel = () => {
        const newPanel = parseInt(addonDialogPanel) - 1
        setAddonDialogPanel(newPanel)
    }

    const resetAddonPanel = () => {
        setAddonDialogPanel(0)
    }

    const getAddToCartQueryVariables = () => {
        const giftCardStyleKey = `addon-${props.id}-gift-styling`
        let addonInputVars = addonQueryInputVars

        // we need to add addon-48677-gift-styling:love-you in the addonInputVars
        if (
            didSelectGiftCard()
        ) {
            const giftCardItems = getCardOptions();
            addonInputVars[giftCardStyleKey] = giftCardItems[selectedGiftCard].label_slug;
        }

        if (didSelectVideoMsg()) {
            const selectedVideoMsgOption = getSelectedVideoMsgOption();
            addonInputVars[selectedVideoMsgOption.field_name] = selectedVideoMsgOption.label_slug;
        }


        if (giftMsgOption === 'NOMSG') {

            // We just make sure to only remove the gift card selected because we don't want to remove the selected extra addon
            if (!isEmpty(addonInputVars[giftCardStyleKey])) {
                delete addonInputVars[giftCardStyleKey];
            }

        }

        /**
         * Adding Hamper box packaging
         */
        if (!isEmpty(choosenHamperBox)) {
            if (!addonInputVars.hasOwnProperty(choosenHamperBox.field_name)) {
                addonInputVars[choosenHamperBox.field_name] = {};
            }

            if (!addonInputVars[choosenHamperBox.field_name].hasOwnProperty(choosenHamperBox.label_slug)) {
                addonInputVars[choosenHamperBox.field_name][choosenHamperBox.label_slug] = {};
            }

            addonInputVars[choosenHamperBox.field_name][choosenHamperBox.label_slug] = 1;
        }

        if (!isEmpty(giftBox.selectedGiftBox)) {
            addonInputVars[giftBox.selectedGiftBox.field_name] = {};

            if (!addonInputVars[giftBox.selectedGiftBox.field_name].hasOwnProperty(giftBox.selectedGiftBox.label_slug)) {
                addonInputVars[giftBox.selectedGiftBox.field_name][giftBox.selectedGiftBox.label_slug] = {};
            }

            addonInputVars[giftBox.selectedGiftBox.field_name][giftBox.selectedGiftBox.label_slug] = 1;
        }

        return {
            clientMutationId: uniqid(), // Generate a unique id.
            productId: parseInt(props.id),
            quantity: productQty,
            extraData: `{\"graphqlAddons\":${JSON.stringify(addonInputVars)}}`
        }
    }

    /**
     * Adding product to cart
     * Original and should be used is ADD_TO_CART
     */
    const [
        addToCart,
        {
            data: addToCartRes,
            loading: addToCartLoading,
            error: addToCartError,
        },
    ] = useAddToCartMutation(getAddToCartQueryVariables(), {
        onCompleted: (data) => {
            if (data.addToCart?.cartItem?.product) {
                // On Success:
                // 1. Make the GET_CART query to update the cart with new values in React context.
                fetchCart();

                // 2. Show View Cart Button
                setMiniCartOpen((prev) => !prev);

                // 3. Reset the product state
                resetProductContextState();
            }
        },
    });

    const grandTotal = () => {
        const optionsTotal = addonsTotal
        const pPrice = parseFloat(productPrice);

        const total = (pPrice + parseFloat(optionsTotal)) * productQty
        return total
    }

    const validateAddToCartForm = () => {
        setGiftCardSelectionError('');

        if (isVoucherProduct) {
            // Since its a voucher product then we return valid true here because the product doesn't have stock level and only have stock status
            return {
                isValid: true
            }
        }

        return {
            isValid: true
        }
    }

    const addToCartButtonClicked = () => {
        const addToCartForm = validateAddToCartForm();
        if (addToCartForm.isValid) {
            addToCart()
        }
    };


    return (
        <ProductContext.Provider value={{
            addonDialog: {
                open: addonDialogOpen, setOpen: setAddonDialogOpen,
                panel: addonDialogPanel, setPanel: setAddonDialogPanel,
                getItems: addons, setItems: setAddons,
                getSelectedAddons: selectedAddons, setSelectedAddons: setSelectedAddons,
                selectedAddonGroup, setSelectedAddonGroup,
                selectedAddonQty, deductAddonQty, increaseAddonQty,
                summary: addonSummary, updateSummary: updateAddonSummary,
                setAddonSummary,
                removeAddonItemSummary,
                getSelectedAddonBysku,
                message: addonDialogMsg, setMessage: setAddonDialogMsg,
                addonsTotal,
                addonsTotalWeight,
                nextPanel: nextAddonPanel, previousPanel: previousAddonPanel, resetPanel: resetAddonPanel,
                fromProductPage: addonFromProductPage, setFromProductPage: setAddonFromProductPage

            },
            selectedAddon: {},
            giftMsgOption, setGiftMsgOption,
            videoMsgInstructionModal: {
                open: videoInstructionModal,
                setOpen: setVideoInstructionModal
            },
            giftBox,
            videoMsg: {
                selectedVideoMessage, setSelectedVideoMessage,
                didSelectVideoMsg, getVideoMsgOptions,
                getSelectedVideoMsgOption
            },
            giftCard: {
                open: giftCardOpen, setOpen: setGiftCardOpen,
                panel: giftCardPanel, setPanel: setGiftCardPanel,
                getItems: getCardOptions(), setItems: setGiftCards,
                selectedGiftCard, setSelectedGiftCard,
                occasionIndex, setOccasionIndex,
                selectedMessage, setSelectedMessage,
                msgEl: giftCardMessageEl,
                selectionError: giftCardSelectionError,
                setSelectionError: setGiftCardSelectionError
            },
            product: {
                validateAddToCartForm,
                qty: productQty,
                id: props.id,
                stockQuantity,
                stockStatus,
                getAddToCartQueryVariables,
                price: productPrice, setPrice: setProductPrice,
                grandTotal: grandTotal(),
                addToCart: {
                    action: addToCart,
                    response: addToCartRes,
                    isLoading: addToCartLoading,
                    error: addToCartError
                },
                addToCartButtonClicked,
                choosenHamperBox,
                ...parsedProduct
            },
            state: {
                quantity: productQty,
            },
            actions: {
                quantity: {
                    decrement,
                    increment,
                    onChange,
                },
            },
        }}>
            {props.children}
        </ProductContext.Provider>
    )
}