import React, { useState } from 'react';
import { Addon, AddonOption, GroupedAddon } from '../typesense/types/product';
import { findIndex } from 'lodash';
import { useLocalStorage } from 'usehooks-ts';

export type GiftBoxProps = {
  selectedGiftBox: AddonOption | undefined;
  setSelectedGiftBox: React.Dispatch<
    React.SetStateAction<AddonOption | undefined>
  >;
  groupIndex: number;
  giftBoxData: Addon | undefined;
  isBlackBox: (sku?: string) => boolean;
};

export const useGiftBox = (addonItems: GroupedAddon[]): GiftBoxProps => {
  const [selectedOption, setSelectedOption] = useState<AddonOption | undefined>(
    undefined,
  );

  const giftBoxIndex = findIndex(addonItems, {
    group_name: 'Gift Boxes',
  });

  const giftBox =
    giftBoxIndex && addonItems[giftBoxIndex]
      ? addonItems[giftBoxIndex].addons[0]
      : undefined;

  const basketSku = 'basket000';

  const isBlackBox = (sku?: string) => {
    if (typeof sku !== 'undefined') {
      if (sku.toLowerCase() === basketSku) {
        return true;
      }
      return false;
    }

    return selectedOption && selectedOption.sku.toLowerCase() === basketSku
      ? true
      : false;
  };

  return {
    selectedGiftBox: selectedOption,
    setSelectedGiftBox: setSelectedOption,
    groupIndex: giftBoxIndex,
    giftBoxData: giftBox,
    isBlackBox: isBlackBox,
  };
};
