import { useRouter } from 'next/router';
import isBuildYourOwnHamper from './product/isBuildYourOwnHamper';

export default function useIsBuildYourOwnHamer() {
    const router = useRouter();
    const { categorySlug, productSlug } = router.query;

    if (
        categorySlug &&
        categorySlug === 'create-your-own-hamper' &&
        isBuildYourOwnHamper(productSlug as string)
    ) {
        return true;
    }

    return false;
}
