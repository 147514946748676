const getImgUrl = (src: string) => {
    let imgSrc = src
        .toString()
        .replace(process.env.NEXT_PUBLIC_WORDPRESS_SITE_URL as string, '');

    imgSrc = imgSrc
        .toString()
        .replace(process.env.NEXT_PUBLIC_WORDPRESS_IMG_CDN_URL as string, '');

    imgSrc = imgSrc.toString().replace('https://cart.gourmetbasket.com.au', '');
    imgSrc = imgSrc.toString().replace('https://cdn.gourmetbasket.com.au', '');

    imgSrc = `${process.env.NEXT_PUBLIC_WORDPRESS_IMG_CDN_URL}${imgSrc}`;

    return imgSrc;
};

export default getImgUrl;
