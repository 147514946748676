import { difference, isEmpty } from 'lodash';
import {
    ITSAttribute,
    ITSDefaultAttributes,
    ITSProduct,
    Variation,
} from '../../utils/typesense/types/product';
import VariationProduct from './VariationProduct';

export default class Product {
    private _attributes?: ITSAttribute[];
    private _stockQuantity: number;
    private _stockStatus: string;
    private _name: string;

    constructor(props: ITSProduct) {
        this._attributes = props.attributes;
        this._stockQuantity = props.stockQuantity ? props.stockQuantity : 0;
        this._stockStatus = props.stockStatus
            ? props.stockStatus
            : 'outofstock';
        this._name = props.name;
    }

    getName() {
        return this._name;
    }

	
    getAttributes() {
        return this._attributes;
    }

    getDefaultAttributes() {
        return {};
    }

    getAvailableAttributes(
        selectedAttributes?: ITSDefaultAttributes
    ): ITSAttribute[] {
        return [];
    }

    getVariations() {
        return {};
    }

    findMatchingVariations(selectedAttributes: ITSDefaultAttributes) {
        return {};
    }

    getVariationsAttributes(selectedAttributes: ITSDefaultAttributes) {
        return {};
    }

    variationMatched(
        variation: Variation,
        selectedAttributes: ITSDefaultAttributes
    ) {
        return false;
    }

    getVariationsByAttributes(
        selectedAttributes: ITSDefaultAttributes
    ): Variation[] {
        return [];
    }

    getVariationByAttributes(
        selectedAttributes: ITSDefaultAttributes
    ): VariationProduct | undefined {
        return;
    }

    getStockQuantity() {
        return this._stockQuantity;
    }

    getStockStatus() {
        return this._stockStatus;
    }

    isInStock() {
        const stockQuantity = this.getStockQuantity();
        const stockStatus = this.getStockStatus();

        if (
            (stockQuantity == null || stockQuantity < 1) &&
            stockStatus !== 'instock'
        ) {
            return false;
        }

        return true;
    }

    getMissingAttributes(selectedAttributes: ITSDefaultAttributes) {
        const attributes = this.getAttributes();
        const attributeSlugs = attributes?.map((attribute) => attribute.slug);
        if (!isEmpty(selectedAttributes)) {
            return difference(attributeSlugs, Object.keys(selectedAttributes));
        }
        return attributeSlugs;
    }

    isSelectedAttributeComplete(selectedAttributes: ITSDefaultAttributes) {
        const missingAttributes = this.getMissingAttributes(selectedAttributes);
        return isEmpty(missingAttributes) ? true : false;
    }
}
