import { isEmpty, isInteger } from "lodash";
import { useContext } from "react"
import { ProductContext } from "src/contexts/ProductContext"

export const useProductContext = () => {
	return useContext(ProductContext)
}

export const numberFormat = (value) =>
	new Intl.NumberFormat('en-IN', {
		style: 'currency',
		currency: 'AUD',
		currencyDisplay: 'narrowSymbol'
	}).format(value);


export const addRecentlyViewedProduct = (productId) => {

	let recent = getRecentlyViewedProduct()

	if (isEmpty(recent)) {
		// If recently viewed is empty then we just save the product id
		saveRecentlyViewedProduct(productId)

		return getRecentlyViewedProduct()
	}

	// if not empty then we check if product id exist and remove
	if (recent.includes(productId)) {
		recent = recent.replace(productId, '')
	}
	// Since we remove the product then we Add the product id again
	recent = "".concat(productId, '|', recent)
	recent = recent.replace('||', '|');
	recent = recent.startsWith('|') ? recent.substring(1) : recent
	recent = recent.endsWith('|') ? recent.substring(0, recent.length - 1) : recent;
	recent = recent.trim()
	
	const limit = 5;
	let recentProductIds = recent.split('|')
	if (recentProductIds.length > limit) {
		recentProductIds = recentProductIds.slice(0, limit)
	}

	saveRecentlyViewedProduct(recentProductIds.join('|'))
	return getRecentlyViewedProduct()
}

export const saveRecentlyViewedProduct = (value) => {
	return localStorage.setItem('woo-next-recent-product', value);
}

export const getRecentlyViewedProduct = () => {

	const recentProductsStr = localStorage.getItem('woo-next-recent-product')
	return recentProductsStr

}

export const getRecentlyViewedProductArr = (exlude = null) => {
	let recentProductsStr = getRecentlyViewedProduct()
	if (isEmpty(recentProductsStr)) {
		return []
	}
	
	// if not empty then we check if product id exist and remove
	if (isInteger(parseInt(exlude)) && recentProductsStr.includes(exlude)) {
		recentProductsStr = recentProductsStr.replace(exlude, '')
		recentProductsStr = recentProductsStr.replace('||', '|');
		recentProductsStr = recentProductsStr.startsWith('|') ? recentProductsStr.substring(1) : recentProductsStr
		recentProductsStr = recentProductsStr.endsWith('|') ? recentProductsStr.substring(0, recentProductsStr.length - 1) : recentProductsStr;
	}

	recentProductsStr = recentProductsStr.trim()
	if (isEmpty(recentProductsStr)) {
		return []
	}

	let recentProductIds = recentProductsStr.split('|')
	return Array.from(recentProductIds, Number); // get the 2nd to the last element
}

export const deleteRecentlyViewedProduct = () => {
	return localStorage.removeItem('woo-next-recent-product');
}

export const RecentlyViewed = {
	add: addRecentlyViewedProduct,
	get: getRecentlyViewedProduct,
	getArr: getRecentlyViewedProductArr,
	delete: deleteRecentlyViewedProduct
}