import Product from '.';
import { ITSProduct } from '../../utils/typesense/types/product';
import VariableProduct from './VariableProduct';

const ProductFactory = {
    getProduct: (product: ITSProduct): VariableProduct | Product => {
        if (product.productType == 'variable') {
            return new VariableProduct(product);
        } else {
            return new Product(product);
        }
    },
};

export default ProductFactory;
