import { ADD_TO_CART } from '../queries/product';
import { useMutation } from '@apollo/client';

const useAddToCartMutation = (input: any, options: any) => {
    /**
     * Adding product to cart
     * Original and should be used is ADD_TO_CART
     */
    const mutation = useMutation(ADD_TO_CART, {
        variables: {
            input: input,
        },
        ...options,
        onError: (error) => {
            if (error) {
                // eslint-disable-next-line no-console
                console.log(error?.graphQLErrors?.[0]?.message ?? '');
            }
        },
    });

    return mutation;
};

export default useAddToCartMutation;
