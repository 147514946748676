import { useRouter } from 'next/router';

export default function useIsVoucher() {
    const router = useRouter();
    const { categorySlug, productSlug } = router.query;

    if (
        categorySlug &&
        categorySlug === 'gift-vouchers' &&
        productSlug &&
        productSlug.includes('gift-voucher')
    ) {
        return true;
    }

    return false;
}
