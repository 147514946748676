import { Dispatch, SetStateAction, useState } from 'react';

export interface QuantityActions {
    decrement: () => void;
    increment: () => void;
    onChange: (_e: React.ChangeEvent<HTMLInputElement>) => void;
    setQuantity?: Dispatch<SetStateAction<number>>;
}

export interface QuantityHook extends QuantityActions {
    quantity: number;
}

export type QuantityProps = {
    max?: number;
    initialValue?: number;
};

export const useQuantity = ({
    max,
    initialValue = 1,
}: QuantityProps = {}): QuantityHook => {
    const [quantity, setQuantity] = useState(initialValue);

    const decrement = () => {
        if (quantity === 1) return;
        setQuantity(quantity - 1);
    };

    const increment = () => {
        if (max && quantity >= max) return;
        setQuantity(quantity + 1);
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value, 10);
        if (max && value >= max) return;
        if (!value) return;

        setQuantity(value);
    };

    return {
        quantity,
        decrement,
        increment,
        onChange,
        setQuantity,
    };
};
