import {
    isEmpty,
    some,
    find,
    every,
    intersection,
    difference,
    groupBy,
    keyBy,
    uniqBy,
    uniq,
    differenceBy,
    sortBy,
    first,
} from 'lodash';
import Product from '.';
import {
    ITSAttribute,
    ITSAttributeOption,
    ITSDefaultAttributes,
    ITSProduct,
    Variation,
} from '../../utils/typesense/types/product';
import { KeyAndValue } from '../../utils/typesense/types/global';

export default class VariationProduct {
    private _stockQuantity: number;
    private _stockStatus: string;
    private _variationId: number;
    private _salePrice: number | undefined;
    private _regularPrice: number;

    constructor(props: Variation) {
        this._stockQuantity = props.stockQuantity ? props.stockQuantity : 0;
        this._stockStatus = props.stockStatus
            ? props.stockStatus
            : 'outofstock';

        this._variationId = props.variationId;

        this._regularPrice = props.regularPrice?.AUD as unknown as number;
        this._salePrice = props.onSale
            ? (props.salePrice?.AUD as unknown as number)
            : undefined;
    }

    getSalePrice() {
        return this._salePrice;
    }

    getRegularPrice() {
        return this._regularPrice;
    }

    getStockQuantity() {
        return this._stockQuantity;
    }

    getStockStatus() {
        return this._stockStatus;
    }

    getId() {
        return this._variationId;
    }

    isInStock() {
        const stockQuantity = this.getStockQuantity();
        const stockStatus = this.getStockStatus();

        if (
            (stockQuantity == null || stockQuantity < 1) &&
            stockStatus !== 'instock'
        ) {
            return false;
        }

        return true;
    }
}
